<template>
  <mdb-container>
    <Publicheader activepage="About"></Publicheader>
    <main>
      <mdb-row>
        <mdb-col md="12" class="mb-4 mt-4">
          <mdb-card class="card-image" style="background-image: url(/images/img_apropos.jpg)">
            <div class="text-white text-center py-5 px-4 rgba-stylish-strong">
              <div>
                <h1 class="card-title h1-responsive pt-3 font-bold"><strong>À propos de Connexion Voyages</strong></h1>
              </div>
            </div>
          </mdb-card>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="8">
          <p>Connexion Voyages est la plateforme par excellence afin d’assister à des conférences sur le voyage entièrement gratuites.</p>

          <p>Elle permet de connecter les voyageurs avec les plus belles destinations du monde, des experts chevronnés évoluant dans le secteur du tourisme depuis de nombreuses années et les conseillers en voyages les mieux formés du Québec qui seront à même de les orienter et de répondre à toutes leurs questions.</p>

          <p>Avec Connexion Voyages, les voyageurs trouveront à la fois l’inspiration pour leur prochain voyage, mais recevront également les conseils précieux des intervenants et des conseillers en voyages.</p>

          <p>Connexion Voyages, c’est l’endroit où les rêves d’évasion passent en mode planification.</p>

          <p>Connexion Voyages, c’est la preuve que l’humain appuyé par la technologie sera toujours plus fort que la technologie seule pour créer des expériences de voyage mémorables.</p>

          <p>Bon voyage!</p>
        </mdb-col>
        <mdb-col md="4">
          <mdb-card>
            <mdb-card-header color="grey darken-3">Demande d'informations</mdb-card-header>
            <mdb-card-body>
              <mdb-container>
                <mdb-row v-if="demandeerr">
                  <mdb-col md="12">
                    <mdb-alert color="danger">
                      {{demandeerr}}
                    </mdb-alert>
                  </mdb-col>
                </mdb-row>
                <mdb-row v-if="demandecomplete">
                  <mdb-col md="12">
                    <mdb-alert color="success" v-html="demandemsg"></mdb-alert>
                  </mdb-col>
                </mdb-row>
                <div v-else>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input label="Votre nom" v-model="demandeinfos.fullname" required />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input label="Votre adresse courriel" v-model="demandeinfos.email" required />
                    </mdb-col>
                  </mdb-row>
                  <mdb-row>
                    <mdb-col md="12">
                      <mdb-input type="textarea" label="Votre message" v-model="demandeinfos.message" :rows="2"/>
                    </mdb-col>
                  </mdb-row>
                  <div class="text-right">
                    <mdb-btn outline="elegant" v-on:click="askquestion()">Soumettre</mdb-btn>
                  </div>
                </div>
              </mdb-container>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </main>
    <Publicfooter></Publicfooter>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Publicheader from '@/components/Publicheader.vue';
    import Publicfooter from '@/components/Publicfooter.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbInput,
        mdbAlert,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Home',
        components: {
            Publicheader,
            Publicfooter,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbAlert,
        },
        data() {
            return {
                demandeerr: null,
                demandecomplete: false,
                demandemsg: null,
                demandeinfos: {
                    fullname: '',
                    email: '',
                    message: '',
                },
            };
        },
        methods: {
            askquestion() {
                this.demandeerr = null;
                const self = this;
                const api = new Apicall();
                api.askquestion(this.demandeinfos, this.$store.state.geoinfos).then((response) => {
                    if (response.status === 'OK') {
                        self.demandecomplete = true;
                        self.demandemsg = 'Votre demande nous a été soumis avec succès. Nous vous répondrons dans les plus brefs délais';
                    } else {
                        self.demandeerr = response.msg;
                    }
                });
            },
        },
    });
</script>
